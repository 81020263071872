import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import landingHowItWorksImage from './landing-how-it-works.png?format=webp&imagetools';

type Props = Qa & ImageProps;

export const LandingHowItWorksImage = ({ qa = 'landing-how-it-works-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 1026,
    htmlHeight: 667,
  };
  const ratio = Number((htmlWidth / htmlHeight).toFixed(2));
  // const h = '100%';
  // const maxHeight = { base: '17rem', md: 'initial' };

  const maxHeight = 17; // rem
  const maxWidth = maxHeight * ratio;

  return (
    <ImagePreloader
      image={{
        src: landingHowItWorksImage,
        alt: t('onboarding.3'),
        htmlWidth,
        htmlHeight,

        h: { base: 'initial', md: 'full' },
        w: { base: 'full', md: 'initial' },

        maxHeight: `${maxHeight}rem`,
        maxWidth: `${maxWidth}rem`,

        ...rest,
      }}
      qa={qa}
    />
  );
};
