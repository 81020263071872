import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import unionDesVillesEtCommunesDeWallonieImage from './union-des-villes-et-communes-de-wallonie.jpg?format=webp&imagetools';

export const UnionDesVillesEtCommunesDeWallonieImage = ({
  qa = 'union-des-villes-et-communes-de-wallonie-image',
  ...rest
}: Qa & ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 2695,
    htmlHeight: 1020,
  };
  const ratio = (htmlWidth / htmlHeight).toFixed(2);
  const h = { base: '3rem', md: '2rem' };

  return (
    <ImagePreloader
      image={{
        src: unionDesVillesEtCommunesDeWallonieImage,
        alt: t('union-des-villes-et-communes-de-wallonie'),
        htmlWidth,
        htmlHeight,

        h,
        maxHeight: h,

        w: {
          base: `calc(${ratio} * ${h.base})`,
          md: `calc(${ratio} * ${h.md})`,
        },
        maxWidth: {
          base: `calc(${ratio} * ${h.base})`,
          md: `calc(${ratio} * ${h.md})`,
        },
        ...rest,
      }}
      qa={qa}
    />
  );
};
