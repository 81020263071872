import { useTheme } from '@chakra-ui/react';
import { AppHead } from '@codeer/handypark-web-common';

export const HeadTags = () => {
  const theme = useTheme();

  const primaryMain = theme.colors.primary.main;

  return (
    <AppHead>
      <meta name='description' content='Description' />
      <link rel='apple-touch-icon' sizes='180x180' href='/assets/icons/180x180.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/assets/icons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/assets/icons/favicon-16x16.png' />
      <link rel='mask-icon' href='/assets/icons/icon.svg' color={primaryMain} />
      <meta name='msapplication-TileColor' content={primaryMain} />
      <meta name='theme-color' content={primaryMain} />
    </AppHead>
  );
};
