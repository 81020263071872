import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import landingWhereToParkImage from './landing-where-to-park.png?format=webp&imagetools';

type Props = Qa & ImageProps;

export const LandingWhereToParkImage = ({ qa = 'landing-where-to-park-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 1368,
    htmlHeight: 890,
  };
  const ratio = Number((Number(htmlWidth) / Number(htmlHeight)).toFixed(2));
  const h = '100%';

  const maxHeight = 17;
  const maxWidth = maxHeight * ratio;

  return (
    <ImagePreloader
      image={{
        src: landingWhereToParkImage,
        alt: t('onboarding.2'),
        htmlWidth,
        htmlHeight,

        h,
        w: 'full',

        maxHeight: `${maxHeight}rem`,
        maxWidth: `${maxWidth}rem`,

        ...rest,
      }}
      qa={qa}
    />
  );
};
