import { ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { ImagePreloader } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import nextGenerationEuImage from './next-generation-eu.png?format=webp&imagetools';

export const NextGenerationEuImage = ({
  qa = 'next-generation-eu-image',
  ...rest
}: Qa & ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  const { htmlWidth, htmlHeight } = {
    htmlWidth: 315,
    htmlHeight: 75,
  };
  const ratio = (htmlWidth / htmlHeight).toFixed(2);
  const h = '2rem';

  return (
    <ImagePreloader
      image={{
        src: nextGenerationEuImage,
        alt: t('next-generation-eu'),
        htmlWidth,
        htmlHeight,

        h,
        maxHeight: h,

        w: `calc(${ratio} * ${h})`,
        maxWidth: `calc(${ratio} * ${h})`,
        ...rest,
      }}
      qa={qa}
    />
  );
};
