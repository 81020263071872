import { ApiError, initializeApiClient, initializeProxyClient } from '@codeer/handypark-common';
import {
  DATA_STATE,
  getCombinedDataState,
  useRemoteTranslations,
} from '@codeer/handypark-web-common';
import { APP_STORAGE_KEYS, APP_USER_DEFAULT_SETTINGS } from '@core';
import { useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const useMain = () => {
  initializeApiClient({
    baseURL: '/api',
    maxRedirects: 0,
  });
  initializeProxyClient({ baseURL: '/proxy/v1', maxRedirects: 0 });

  // Make sure settings are stored in localStorage
  useLocalStorage(APP_STORAGE_KEYS.USER_SETTINGS, APP_USER_DEFAULT_SETTINGS);
  const { hasFetchedRemoteTranslations } = useRemoteTranslations();

  const error: ApiError | undefined = useMemo(() => {
    return undefined;
  }, []);

  const dataState = useMemo(() => {
    if (!hasFetchedRemoteTranslations) {
      return DATA_STATE.LOADING;
    }
    return getCombinedDataState([]);
  }, [hasFetchedRemoteTranslations]);

  return {
    mainDataState: dataState,
    mainError: error,
  };
};
