import { Box } from '@chakra-ui/react';
import {
  AppHeading,
  AppLink,
  COLOR_SCHEMES,
  HEADING_STYLING_KEYS,
  Paragraph,
  TAppBoxProps,
} from '@codeer/handypark-web-common';
import { ReactNode } from 'react';

export type TLandingHowToUseBlockProps = TAppBoxProps & {
  icon?: ReactNode;
  title: string;
  content?: string;
  linkText: string;
  href?: string;
};

export const LandingHowToUseBlock = ({
  icon,
  title,
  content,
  linkText,
  href = '#',
  ...rest
}: TLandingHowToUseBlockProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} {...rest}>
      <Box display={'flex'} flexDirection={'row'} gap={2.5}>
        {icon && (
          <Box
            aria-hidden={true}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            w={6}
            h={6}
            color={`${COLOR_SCHEMES.PRIMARY}.main`}
          >
            {icon}
          </Box>
        )}
        <AppHeading as={HEADING_STYLING_KEYS.H3} textStyle={'subtitle'}>
          {title}
        </AppHeading>
      </Box>

      {content && <Paragraph>{content}</Paragraph>}

      <AppLink
        alignSelf={'flex-start'}
        href={href}
        aria-label={linkText}
        color={`${COLOR_SCHEMES.PRIMARY}.main`}
      >
        {linkText}
      </AppLink>
    </Box>
  );
};
